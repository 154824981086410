import React, { useCallback, useMemo } from 'react'
import { Card, Icon, theme } from '@damen/ui'
import { useModalDownloadContext } from '@/contexts/modalDownloadContext'
import { HitWrapper } from './types'
import { SingleHitWrapper, StyledLink } from './styles'
import Link from '../../Link'
import type { Highlight } from '../../UI/ModelList/types'
import { useTranslationContext } from '../Context'
import { ProductIndexItem } from '@/queries/schema.generated'
import { AlgoliaInStockProductOverviewFragment } from '@/queries/fragments/AlgoliaInStockProductOverview.generated'

import { getSustainableMarkWithPopover } from '@/utils/helpers/sustainableIcon'
import { onDownloadClick, useDownloadModal } from '@/hooks/modal/download'
import { DownloadModalProps } from '@/components/UI/Modal/types'

const ProductCardHit: React.FC<React.PropsWithChildren<HitWrapper>> = ({ id, hit, children }) => {
  const {
    previewImage,
    documents: documentUrls,
    description,
    name,
    highlightsFormatted,
    labels: productLabels,
    isInStock,
    sustainableMark,
    url,
    family,
    range,
  } = hit as unknown as ProductIndexItem & Record<string, any>
  const context = useTranslationContext()
  const { setModal: setDownloadModal } = useModalDownloadContext()

  const labels: AlgoliaInStockProductOverviewFragment['defaultCardLabels'] = context.labels

  const buttonComponent = labels.ctaText
    ? () => (
        <Link.VNext
          type="button"
          link={url}
          dataAttrs={{ 'data-gtm-product': name, 'data-gtm-action': 'quote' }}
          text={labels.ctaText}
        >
          {children}
        </Link.VNext>
      )
    : null

  const mappedHighlights: Highlight[] = highlightsFormatted
    ? Object.values(highlightsFormatted).map((highlight: any) => ({
        id: highlight.id,
        label: highlight.unitSymbol ? `${highlight.label} (${highlight.unitSymbol})` : highlight.label,
        value: highlight.value ? highlight.value.toString() : '-',
      }))
    : []

  const openDownloadModal = useCallback(() => {
    const downloadModalData = {
      type: 'download',
      data: {
        link: {
          cachedUrl: documentUrls[0],
        },
        title: name,
        product: {
          id,
          name,
          family,
          range,
        },
      },
    }
    setDownloadModal(downloadModalData as DownloadModalProps)
  }, [documentUrls, name, id, family, range, setDownloadModal])

  useDownloadModal(openDownloadModal)

  const downloadButton =
    labels?.downloadText && documentUrls?.[0]
      ? () =>
          isInStock ? (
            <StyledLink
              dataAttrs={{ 'data-gtm-product': name, 'data-gtm-action': 'download' }}
              link={documentUrls[0]}
              text={labels.downloadText}
              type="button"
              icon={() => <Icon.FileDownload width={16} height={16} fill={theme.colors.blue} />}
              iconSize={16}
              color="white"
            />
          ) : (
            <Link.VNext
              onClick={onDownloadClick}
              dataAttrs={{ 'data-gtm-product': name, 'data-gtm-action': 'download' }}
              text={labels.downloadText}
              type="button"
              color="white"
              icon={Icon.FileDownload}
              iconSize={16}
              link={null}
            />
          )
      : null

  const image = useMemo(() => {
    if (!previewImage) return undefined
    return {
      alt: name,
      src: previewImage,
      sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
    }
  }, [previewImage, name])

  return (
    <SingleHitWrapper key={id}>
      <Card.ProductCard
        title={name}
        titleLink={({ children }) => (
          <Link.VNext type="link" link={url}>
            {children}
          </Link.VNext>
        )}
        tags={productLabels}
        buttonComponent={buttonComponent}
        image={image}
        description={description}
        highlights={mappedHighlights}
        downloadButton={downloadButton}
        titleIcon={getSustainableMarkWithPopover(sustainableMark, 30, 36)}
      />
    </SingleHitWrapper>
  )
}

export default ProductCardHit
